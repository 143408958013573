(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name myOrder.factory:MyOrder
   *
   * @description
   *
   */
  angular
    .module('myOrder')
    .factory('MyOrder', MyOrder);

  function MyOrder($rootScope, $http, consts) {
    var MyOrderBase = {};
    MyOrderBase.order = {
      items: [],
      totalPrice: 0
    };

    MyOrderBase.totalItems = 0;

    var createNewOrder = function(){
      MyOrderBase.order = {
        items: [],
        totalPrice: 0
      };
      MyOrderBase.totalItems = 0;
    }

    var createServerOrderObject = function(order) {
      var serverOrder = {
        Payment: order.payment,
        IsDelivery: order.orderType == "delivery",
        IsFutureOrder: order.orderTypeTime == "future",
        BranchId: order.branch.Id,
        OrderItems: [],
        OrderPizzas: [],
        OrderCombos: []
      };
      order.items.forEach(function(item) {
        if (item.Type == "pizza") {
          var serverPizza = {
            PizzaId: item.Id,
            Amount: item.amount,
            Toppings: [],
            SizeId: item.pizzaPrice.PizzaSizeId,
            SpecialRequests: item.requests
          }
          if (item.toppings != undefined) {
            item.toppings.forEach(function(top) {
              serverPizza.Toppings.push({
                ToppingId: top.toppingId,
                Quarter1: top.quarterNums.indexOf(1) != -1,
                Quarter2: top.quarterNums.indexOf(2) != -1,
                Quarter3: top.quarterNums.indexOf(3) != -1,
                Quarter4: top.quarterNums.indexOf(4) != -1
              });
            });
          }
          serverOrder.OrderPizzas.push(serverPizza);
        } else if (item.Type == "item") {
          var serverItem = {
            ItemId: item.Id,
            Amount: item.amount,
            Garnishes: item.toppings,
            SpecialRequests: item.requests
          }
          serverOrder.OrderItems.push(serverItem);
        } else if (item.Type == "combo") {
          var serverCombo = {
            ComboId: item.Id,
            Amount: item.amount,
            Name: item.Name,
            Items: [],
            Pizzas: []
          }
          if (item.Pizzas) {
            //Combo pizzas
            item.Pizzas.forEach(function(comboPizza) {
              comboPizza.items.forEach(function(item) {
                var serverPizza = {
                  PizzaId: item.Id,
                  Amount: item.amount == 0 ? 1 : item.amount,
                  Toppings: [],
                  SizeId: item.pizzaPrice ? item.pizzaPrice.PizzaSizeId : null,
                  SpecialRequests: item.requests,
                  ComboPizzaId: item.comboPizzaId
                }
                if (item.toppings != undefined) {
                  item.toppings.forEach(function(top) {
                    serverPizza.Toppings.push({
                      ToppingId: top.toppingId,
                      Quarter1: top.quarterNums.indexOf(1) != -1,
                      Quarter2: top.quarterNums.indexOf(2) != -1,
                      Quarter3: top.quarterNums.indexOf(3) != -1,
                      Quarter4: top.quarterNums.indexOf(4) != -1
                    });
                  });
                }
                serverCombo.Pizzas.push(serverPizza);
              })
            })
          }
          if (item.Items) {
            //Combo items
            item.Items.forEach(function(comboItem) {
              comboItem.items.forEach(function(item) {
                var serverItem = {
                  ItemId: item.Id,
                  Amount: item.amount == 0 ? 1 : item.amount,
                  Garnishes: item.toppings,
                  SpecialRequests: item.requests,
                  ComboItemId: item.comboItemId
                }
                serverCombo.Items.push(serverItem);
              })
            })
          }
          serverOrder.OrderCombos.push(serverCombo);
        }
      })
      return serverOrder;
    }



    MyOrderBase.getItemsInOrderCount = function(){
      return MyOrderBase.totalItems;
    }

    MyOrderBase.addItemToOrder = function(item) {

      if (MyOrderBase.order.ordered) {
        createNewOrder();
      }

      item.Type = "item";
      MyOrderBase.order.items.push(item);
      if (item.amount == undefined) item.amount = 1;
      MyOrderBase.totalItems += item.amount;
      $rootScope.$broadcast("itemAddedToOrder", MyOrderBase.totalItems);

      if (item.type == "pizza") {
        var price = item.pizzaPrice.Price;
      }

      MyOrderBase.order.totalPrice += item.Price * item.amount;
    };

    MyOrderBase.addPizzaToOrder = function(item) {

      if (MyOrderBase.order.ordered) {
        createNewOrder();
      }

      item.Type = "pizza";
      MyOrderBase.order.items.push(item);
      if (item.amount == undefined) item.amount = 1;
      MyOrderBase.totalItems += item.amount;
      $rootScope.$broadcast("itemAddedToOrder", MyOrderBase.totalItems);

      var price = item.pizzaPrice.Price;
      item.toppings.forEach(function(top) {
        if (top.totalPrice != undefined) price += top.totalPrice;
      });
      item.Price = price;
      MyOrderBase.order.totalPrice += item.Price * item.amount;
    };


    MyOrderBase.addComboToOrder = function(combo, pizzas, items) {

      if (MyOrderBase.order.ordered) {
        createNewOrder();
      }

      var comboExtraPrice = 0;
      MyOrderBase.order.totalPrice += combo.Price;
      //Check items toppings
      combo.ItemCombos.forEach(function(itemCombo, index) {
        for (var i = 0; i < itemCombo.Quantity; i++) {
          var currentItem = items[index].items[i];
          var garnishedOrderedByPrice = _.orderBy(currentItem.toppings, function(e) {
            return -1 * e.Price
          })
          if (currentItem.toppings != undefined) {
            for (var j = 0; j < currentItem.toppings.length; j++) {
              if (j < itemCombo.MaxGarnishes) {
                garnishedOrderedByPrice[j].Price = 0
              } else {
                MyOrderBase.order.totalPrice += garnishedOrderedByPrice[j].Price;
                comboExtraPrice += garnishedOrderedByPrice[j].Price;
              }
            }
          }
        }
      })

      //Check pizzas toppings
      combo.PizzaCombos.forEach(function(pizzaCombo, index) {
        for (var i = 0; i < pizzaCombo.Quantity; i++) {
          var currentPizza = pizzas[index].items[i];
          var toppingsOrderedByPrice = _.orderBy(currentPizza.toppings, function(e) {
            return -1 * e.quarterPrice
          })

          var toppingsTotalCount = 0;
          if (currentPizza.toppings != undefined) {
            for (var j = 0; j < currentPizza.toppings.length; j++) {
              for (var quarter = 1; quarter <= currentPizza.toppings[j].quarterNums.length; quarter++) {
                toppingsTotalCount += 0.25;
                if (toppingsTotalCount <= pizzaCombo.MaxToppings) {
                  toppingsOrderedByPrice[j].totalPrice -= toppingsOrderedByPrice[j].quarterPrice;
                } else {
                  MyOrderBase.order.totalPrice += toppingsOrderedByPrice[j].quarterPrice;
                  comboExtraPrice += toppingsOrderedByPrice[j].quarterPrice;
                }
              }
            }
          }
        }
      });

      var myCombo = {
        Id: combo.Id,
        Name: combo.Name,
        Pizzas: pizzas,
        Items: items,
        Type: 'combo',
        Price: combo.Price,
        amount: 1,
        comboExtraPrice: comboExtraPrice
      }
      MyOrderBase.order.items.push(myCombo);
      MyOrderBase.totalItems += 1;
      $rootScope.$broadcast("itemAddedToOrder", MyOrderBase.totalItems);
    }

    MyOrderBase.getOrder = function(item) {
      return MyOrderBase.order;
    };

    MyOrderBase.getTotalOrderPrice = function() {
      return MyOrderBase.order.totalPrice;
    }

    MyOrderBase.removeItemFromOrder = function(item, index) {
      MyOrderBase.order.items.splice(index, 1);
      MyOrderBase.order.totalPrice -= item.Price * item.amount;
      if (item.Type == 'combo') {
        MyOrderBase.order.totalPrice -= item.comboExtraPrice * item.amount;
      }
      MyOrderBase.totalItems -= item.amount;
      $rootScope.$broadcast("itemAddedToOrder", MyOrderBase.totalItems);
    }

    MyOrderBase.addItemAmount = function(index) {
      MyOrderBase.order.items[index].amount++;
      MyOrderBase.order.totalPrice += MyOrderBase.order.items[index].Price;
      if (MyOrderBase.order.items[index].Type == 'combo') {
        MyOrderBase.order.totalPrice += MyOrderBase.order.items[index].comboExtraPrice;
      }
      MyOrderBase.totalItems += 1;
      $rootScope.$broadcast("itemAddedToOrder", MyOrderBase.totalItems);
    }

    MyOrderBase.subItemAmount = function(index) {
      MyOrderBase.order.items[index].amount--;
      MyOrderBase.order.totalPrice -= MyOrderBase.order.items[index].Price;
      if (MyOrderBase.order.items[index].Type == 'combo') {
        MyOrderBase.order.totalPrice -= MyOrderBase.order.items[index].comboExtraPrice;
      }
      MyOrderBase.totalItems -= 1;
      $rootScope.$broadcast("itemAddedToOrder", MyOrderBase.totalItems);
    }

    MyOrderBase.paymentRequest = function(order, loginToken, tranzilaToken, cardExpiration, user) {
      var serverOrder = createServerOrderObject(order);
      order.FirstName = user.FirstName;
      order.LastName = user.LastName;
      order.Phone = user.Phone;
      order.ExtraPhone = user.extraPhone;
      order.Address = user.Address;
      order.Comments = user.comments;

      return $http({
        method: "post",
        url: consts.serverUrl + "Payment/PaymentRequest",
        data: {
          order: serverOrder,
          userLoginToken: loginToken,
          tranzilaToken: tranzilaToken,
          cardExpiration: cardExpiration
        }
      });
    }



    return MyOrderBase;
  }
}());
